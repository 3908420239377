@import url("./colors");

.breakpoint-sm(@rules) {
  @media only screen and (min-width: 768px) {
    @rules();
  }
}

.breakpoint-md(@rules) {
  @media only screen and (min-width: 992px) {
    @rules();
  }
}

.breakpoint-lg(@rules) {
  @media only screen and (min-width: 1200px) {
    @rules();
  }
}

.breakpoint-xl(@rules) {
  @media only screen and (min-width: 1600px) {
    @rules();
  }
}

.breakpoint-xxl(@rules) {
  @media only screen and (min-width: 1900px) {
    @rules();
  }
}

.breakpoint-xxxl(@rules) {
  @media only screen and (min-width: 2900px) {
    @rules();
  }
}

.breakpoint(@width, @rules) {
  @media only screen and (min-width: @width) {
    @rules();
  }
}

.clearfix() {
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}

.clickable-text() {
  cursor: pointer;
  color: darken(#fff, 10%);
  user-select: none;

  &:hover {
    color: #fff;
  }
}
