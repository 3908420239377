@import url("/styles/common/variables");
@import url("/styles/common/typography.less");

.ant-card {
  border: none;

  &-head {
    background: @cardHeader;
    color: @white;
    padding: 0 @sig-card-padding-base;

    &-title {
      color: @white;
      font-size: 21px;
      .body-font-normal;
    }
  }

  &-body {
    padding: @sig-card-padding-base;
  }

  &-wider-padding {
    .ant-card-head {
      padding: 0 @sig-card-padding-base;
    }

    .ant-card-body {
      padding: @sig-card-padding-base;
    }
  }
}
