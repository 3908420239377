.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-wrap {
  white-space: nowrap;
}

.no-margin {
  margin: 0;
}

.no-print,
.no-print * {
  display: none !important;
}
