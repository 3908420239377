/* stylelint-disable no-descending-specificity */
.table-padding {
  padding: 8px;
}

.ant-table {
  table {
    font-size: 14px;
    line-height: 20px;
  }

  &.ant-table-bordered {
    .ant-table-thead {
      tr {
        th {
          border-color: lighten(@dkBlue, 10%);
        }
      }
    }

    .ant-table-title {
      border: none;
      .body-font-bold;

      font-size: 16px;
    }
  }
}

.ant-table-thead {
  tr {
    th {
      background: @dkBlue;
      color: @white;
      word-break: break-word;
      .body-font-bold;
      .table-padding;
    }

    th .anticon,
    th .anticon:hover {
      color: @white;
      transition: none;
    }
  }
}

.ant-table-tbody {
  tr {
    td {
      word-break: break-word;
      position: relative;
      .table-padding;
    }
  }
}

.ant-table-scroll {
  box-shadow: inset -2px 2px 4px rgb(0 0 0 / 20%);
}

.ant-table-scroll-position-right {
  .ant-table-scroll {
    box-shadow: none;
  }
}

// Ant bug in 2.7. This had a hanging gray box on the table heading. This removes it
.ant-table-fixed-header .ant-table-scroll::after {
  background: transparent;
}
