/* stylelint-disable font-family-no-duplicate-names */
/* stylelint-disable font-family-name-quotes */
@import url("./mixins");
@import url("./colors");
@font-prefix: "../../fonts/";

@font-face {
  font-family: "ProximaNova-Bold";
  src: url("@{font-prefix}2FB7AA_9_0.eot");
  src: url("@{font-prefix}2FB7AA_9_0.eot?#iefix") format("embedded-opentype"),
    url("@{font-prefix}2FB7AA_9_0.woff2") format("woff2"), url("@{font-prefix}2FB7AA_9_0.woff") format("woff"),
    url("@{font-prefix}2FB7AA_9_0.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Light";
  src: url("@{font-prefix}2FB7AA_A_0.eot");
  src: url("@{font-prefix}2FB7AA_A_0.eot?#iefix") format("embedded-opentype"),
    url("@{font-prefix}2FB7AA_A_0.woff2") format("woff2"), url("@{font-prefix}2FB7AA_A_0.woff") format("woff"),
    url("@{font-prefix}2FB7AA_A_0.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Regular";
  src: url("@{font-prefix}2FB7AA_B_0.eot");
  src: url("@{font-prefix}2FB7AA_B_0.eot?#iefix") format("embedded-opentype"),
    url("@{font-prefix}2FB7AA_B_0.woff2") format("woff2"), url("@{font-prefix}2FB7AA_B_0.woff") format("woff"),
    url("@{font-prefix}2FB7AA_B_0.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova-Semibold";
  src: url("@{font-prefix}2FB7AA_C_0.eot");
  src: url("@{font-prefix}2FB7AA_C_0.eot?#iefix") format("embedded-opentype"),
    url("@{font-prefix}2FB7AA_C_0.woff2") format("woff2"), url("@{font-prefix}2FB7AA_C_0.woff") format("woff"),
    url("@{font-prefix}2FB7AA_C_0.ttf") format("truetype");
}

.body-font-normal() {
  font-family: ProximaNova-Regular, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
}

.body-font-semibold() {
  font-family: ProximaNova-Semibold, sans-serif;
  font-weight: normal !important;
}

.body-font-bold() {
  font-family: ProximaNova-Bold, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: normal !important;
}

.body-font-light() {
  font-family: ProximaNova-Light, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
  font-weight: 300 !important;
}

.heading(@heading-size) {
  .body-font-semibold;

  -webkit-font-smoothing: antialiased;
  font-size: @heading-size;
  line-height: @heading-size * 1.5;
  color: @sig-dark-grey;
}

h1 {
  .heading(38px);
}

h2 {
  .heading(30px);
}

h3 {
  .heading(24px);
}

h4 {
  .heading(21px);
}

h5 {
  .heading(18px);
}

h6 {
  .heading(16px);
}

p {
  font-size: 15px;
}

strong {
  .body-font-bold;
}
