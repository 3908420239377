.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  a {
    color: rgb(255 255 255 / 67%);
    font-size: 14px;
  }
  .body-font-bold;

  border: none;
  line-height: 52px;
  background: @topBarBg;
}

.ant-menu-submenu-horizontal {
  > .ant-menu {
    margin: 0;
  }

  .ant-menu-sub {
    .ant-menu-item {
      padding: 0 16px 0 20px;
    }
  }
}
